import {useMemo} from 'react';
import { Form, InputGroup } from "react-bootstrap";
import { getRequiredName } from '../utilities/utils';


const FormItemWithBold = ({
    as: Control = Form.Control,
    data,
    onChange,
    errors,
    isBold,
    isBoldMap,
    name,
    skipBold = false,
    placeholder,
    isRequiredMap,
    ...props
}) => {

    const requiredMemo = useMemo(() => getRequiredName(name), [isRequiredMap, name])

    return (
        <>
            <Form.Group className={`mb-${!skipBold && (isBoldMap[`${name}Bold`] || isBold) ? 1 : 3}`}>
                <Form.Label>{placeholder}{isRequiredMap && isRequiredMap[requiredMemo] != undefined && isRequiredMap[requiredMemo] === true ? (<span style={{ color: 'red' }}> *</span>) : null}</Form.Label>
                <InputGroup className="mb-3 form-item-with-bold-input-group">
                    <Control
                        onChange={onChange}
                        name={name}
                        value={data[name]}
                        isInvalid={errors && errors[name]}
                        placeholder={placeholder}
                        {...props}
                    />
                    {errors && <Form.Control.Feedback type="invalid">{errors[name]}</Form.Control.Feedback>}
                    {!skipBold && (isBoldMap[name] || isBold) && (
                        <>
                            <InputGroup.Text>Bold</InputGroup.Text>
                            <InputGroup.Checkbox
                                id={`${name}-bold-check`}
                                name={`${name}Bold`}
                                checked={data[`${name}Bold`] || false}
                                onChange={onChange}
                                disabled={props.checkboxDisabled}
                            />
                        </>
                    )}
                </InputGroup>
            </Form.Group>
        </>
    );
}

export default FormItemWithBold;
