// import { useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import FormItemWithBold from "../FormItemWithBold";
// import CropModal from "../CropModal";
import "./personalizeForm.css";

const CompanyInfo = ({
    companyInfo,
    companyInfoFormChangeHandler,
    goToTab,
    highlightElement,
    logoFileSelectedHandler,
    coloredQuantities,
    isBoldMap,
    allowAccountInfoTab,
    children,
    isRequiredMap
}) => {
    // const [imageToCrop, setImageToCrop] = useState("");

    const nextTab = () => {
        goToTab("AccountInfo");
    };

    const previousTab = () => {
        goToTab("StartPage");
    };

    const fileSelected = (e) => {
        logoFileSelectedHandler(e);
    }
    // const fileSelected = async (event) => {
    //     const file = event.target.files[0];
    //     const base64 = await toBase64(file);
    //     setImageToCrop(base64);
    // };

    const commonProps = {
        data: companyInfo,
        onChange: companyInfoFormChangeHandler,
        onFocus: highlightElement,
        isBoldMap,
        type: "text",
    };

    return (
        <div className="personalizeFrom">
            {/* <CropModal
                show={!!imageToCrop}
                close={() => setImageToCrop("")}
                image={imageToCrop}
                save={logoFileSelectedHandler}
            /> */}
            <Form onSubmit={(e) => e.preventDefault()} noValidate>
                <Row>
                    <Col sm={12} className="form-col">
                        <FormItemWithBold
                            required
                            name="companyName"
                            placeholder="Company Name"
                            {...commonProps}
                            skipBold
                            isRequiredMap={isRequiredMap}
                        />
                    </Col>
                    <Col sm={12} className="form-col">
                        <FormItemWithBold name="companyAddress" placeholder="Company Address" {...commonProps} isBold isRequiredMap={isRequiredMap} />
                    </Col>
                    <Col sm={12} className="form-col">
                        <FormItemWithBold name="companyAddress2" placeholder="Company Address 2" {...commonProps} isRequiredMap={isRequiredMap} />
                    </Col>
                    <Col sm={4} className="form-col">
                        <FormItemWithBold name="city" placeholder="City" {...commonProps} isRequiredMap={isRequiredMap} />
                    </Col>
                    <Col sm={4} className="form-col">
                        <FormItemWithBold name="state" placeholder="State" {...commonProps} isRequiredMap={isRequiredMap} />
                    </Col>
                    <Col sm={4} className="form-col">
                        <FormItemWithBold name="zip" placeholder="Zipcode" {...commonProps} isRequiredMap={isRequiredMap} />
                    </Col>
                    <Col sm={12} className="form-col">
                        <FormItemWithBold name="phoneNumber" placeholder="Phone Number" {...commonProps} isRequiredMap={isRequiredMap} />
                    </Col>
                    <Col sm={12} className="form-col">
                        <FormItemWithBold
                            name="additionalInformation"
                            placeholder="Additional Information"
                            {...commonProps}
                            isRequiredMap={isRequiredMap}
                        />
                    </Col>
                    {isBoldMap.cityStateZip && (
                        <Col sm={4} className="form-col">
                            <Form.Group className="mb-3">
                                <Form.Check
                                    id={`cityStateZip-bold-check`}
                                    label="Bold"
                                    name="cityStateZipBold"
                                    checked={companyInfo.cityStateZipBold || false}
                                    onChange={companyInfoFormChangeHandler}
                                    isRequiredMap={isRequiredMap}
                                />
                            </Form.Group>
                        </Col>
                    )}
                </Row>
                <hr />
                <div className="logoOption">
                    <h4>Your Logo</h4>
                    <div>
                        <div>
                            <label>
                                <input
                                    type="radio"
                                    className="custom-radio"
                                    id="noLogo"
                                    name="logoType"
                                    checked={companyInfo.logoType === "0"}
                                    value="0"
                                    onChange={companyInfoFormChangeHandler}
                                />
                                No Logo
                            </label>
                        </div>
                        <div>
                            <label>
                                <input
                                    type="radio"
                                    className="custom-radio"
                                    id="addYourLogoForFree"
                                    name="logoType"
                                    checked={companyInfo.logoType === "1"}
                                    value="1"
                                    onChange={companyInfoFormChangeHandler}
                                />
                                Add Your Logo for Free
                            </label>
                        </div>
                    </div>
                    {companyInfo.logoType === "1" && (
                        <>
                            <div>
                                <h4>Logo file</h4>
                                <input onChange={fileSelected} className="form-control form-control-sm" type="file" accept="image/*" required />
                                { !companyInfo.customerWillProvideImageOnEmail && (!companyInfo.logoBase64 || companyInfo.logoBase64.length == 0) && <div style={{ display: 'inherit' }} className="invalid-feedback">Please select a logo file</div>}
                                <Form.Group className="mb-1 mt-1">
                                    <Form.Check
                                        name="customerWillProvideImageOnEmail"
                                        checked={companyInfo.customerWillProvideImageOnEmail}
                                        onChange={companyInfoFormChangeHandler}
                                        inline
                                        label="I will email to: Order@AbcCheckPrinting.Com"
                                        type="checkbox"
                                        id="customerWillProvideImageOnEmail"
                                    />
                                </Form.Group>
                                {companyInfo.customerWillProvideImageOnEmail && (
                                    <small className="text-danger">(Please note: this may delay your order)</small>
                                )}
                                <p className="fw-lighter">
                                    Please include your reference order number when sending your logo via email.
                                </p>
                            </div>
                            <div>
                                <h4>Logo Position</h4>
                                <div>
                                    <label>
                                        <input
                                            type="radio"
                                            className="custom-radio"
                                            name="logoPosition"
                                            id="sideOfImprint"
                                            checked={companyInfo.logoPosition === "0"}
                                            value="0"
                                            onChange={companyInfoFormChangeHandler}
                                        />
                                        Side of Imprint
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        <input
                                            type="radio"
                                            className="custom-radio"
                                            id="insteadOfCompanyName"
                                            name="logoPosition"
                                            checked={companyInfo.logoPosition === "1"}
                                            value="1"
                                            onChange={companyInfoFormChangeHandler}
                                        />
                                        Instead of Company name
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        <input
                                            type="radio"
                                            className="custom-radio"
                                            name="logoPosition"
                                            id="insteadOfCompanyNameAndInfo"
                                            checked={companyInfo.logoPosition === "2"}
                                            value="2"
                                            onChange={companyInfoFormChangeHandler}
                                        />
                                        Instead of Company name & Info
                                    </label>
                                </div>
                            </div>
                            <div>
                                <h4>Logo Color</h4>
                                <div>
                                    <label>
                                        <input
                                            type="radio"
                                            className="custom-radio"
                                            name="logoColor"
                                            checked={companyInfo.logoColor === "0"}
                                            value="0"
                                            onChange={companyInfoFormChangeHandler}
                                        />
                                        Black and White (No additional charges)
                                    </label>
                                </div>
                                {coloredQuantities && coloredQuantities.length > 0 &&
                                    (<div className="d-flex flex-column">
                                        <label>
                                            <input
                                                type="radio"
                                                className="custom-radio"
                                                name="logoColor"
                                                checked={companyInfo.logoColor === "1"}
                                                value="1"
                                                onChange={companyInfoFormChangeHandler}
                                            />
                                            Colored
                                        </label>
                                    </div>)
                                }
                                {companyInfo.logoColor === "1" && (
                                    <div>
                                        <p className="fw-lighter">For an additional fee, print your LOGO in COLOR</p>
                                        {(coloredQuantities && coloredQuantities.length == 0) ? <p>No available colored options</p> : ''}
                                        {coloredQuantities.map((x) => (
                                            <span key={x.id}>
                                                {" "}
                                                Up to {x.quantity} + ${x.price.toFixed(2)} <br />{" "}
                                            </span>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </div>
                <hr />
                <div
                    className="btn-toolbar justify-content-between"
                    role="toolbar"
                    aria-label="Toolbar with button groups"
                >
                    <div style={{ display: "inherit" }}>
                        <div className="me-1">
                            <Button variant="warning" onClick={previousTab}>Back</Button>
                        </div>
                        <div className="">
                            <Button disabled={!allowAccountInfoTab} onClick={nextTab}>
                                Next
                            </Button>
                        </div>
                    </div>
                    <div>
                        {children}
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default CompanyInfo;
