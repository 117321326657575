import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Tabs, Tab, Button, Spinner, Alert } from "react-bootstrap";

import "./personalizeCheck.css";

import CompanyInfo from "../../components/personalizeForm/companyInfo.js";
import AccountInfo from "../../components/personalizeForm/accountInfo";
import CheckInfo from "../../components/personalizeForm/checkInfo";
import StartPage from "../../components/personalizeForm/startPage";

import {
    defaultAccountInfo,
    defaultCompanyInfo,
    defaultCheckInfo,
    defaultPersonalizeInfo
} from "../../utilities/defaultStates";

import {
    ADD_TO_CART_URL,
    loadBackgrounds,
    loadProductVariantFromWPProductId,
    getProductJsonFromWP,
    BackEndUrl,
    getProduct
} from "../../utilities/requests";

import {
    extraSignatureChangeHandler,
    intializeCanvas,
    linesOnCheckHandler,
    resizeCanvas,
    updateCanvasValues,
    highlightAnnotation,
    updateValuesWrapper as updateCanvasValuesWrapper,
    changeLogoPosition,
    addLogo,
    clearLogo,
    boldHandler,
    removeCompanyAddress2IfEmpty,
} from "../../utilities/canvas";

import { fabric } from 'fabric';
import { ActiveTabEnum, getBase64FromUrl, isEmpty, toBase64 } from "../../utilities/utils";
import { useQuery } from "../../utilities/hooks";

try {
    fabric.textureSize = 16384 * 5;
} catch (_err) { }

const PersonalizeCheck = () => {
    const params = useQuery();

    const [activeTab, setActiveTab] = useState(ActiveTabEnum.StartPage);
    const [maxActiveTab, setMaxActiveTab] = useState(ActiveTabEnum.StartPage);
    const [productVariant, setProductVariant] = useState();
    const [backgrounds, setBackgrounds] = useState();
    const [loadProductError, setLoadProductError] = useState(false);

    /** @type {[fabric.Canvas, (state: fabric.Canvas) => void]} */
    const [canvas, setCanvas] = useState();
    const [fieldMapping, setFieldMapping] = useState();
    const [isRequiredMap, setIsRequiredMap] = useState({});
    const [isBoldMap, setIsBoldMap] = useState({});
    const [loading, setLoading] = useState(true);
    const [textAreaValue, setTextAreaValue] = useState();

    const [quantities, setQuantities] = useState();
    const [coloredQuantities, setColoredQuantities] = useState();
    const [personalizeInfo, setPersonalizeInfo] = useState(defaultPersonalizeInfo)
    const [companyInfo, setCompanyInfo] = useState(defaultCompanyInfo);
    const [accountInfo, setAccountInfo] = useState(defaultAccountInfo);
    const [checkInfo, setCheckInfo] = useState(defaultCheckInfo);
    const [fieldMappingDefaultPositions, setFieldMappingDefaultPositions] = useState();

    const [showPreview, setShowPreview] = useState(false);
    const [showFront, setShowFront] = useState(true);
    const [showPreviewGlobal, setShowPreviewGlobal] = useState(false);
    const [canvasHeight, setCanvasHeight] = useState(350);
    const [productData, setProductData] = useState();
    const [price, setPrice] = useState(0);

    const id = params.get("id");

    useEffect(() => {
        setPostRequestValue();
    }, [personalizeInfo, companyInfo, accountInfo, checkInfo])

    const resizeCallback = useCallback(() => {
        resizeCanvas(canvas, productVariant);
        setCanvasHeight(prev => canvas?.height || prev);
    }, [canvas, productVariant]);

    const initializeQuantitiesCallback = useCallback(() => {
        const _quantitiesQuery = params.getAll("qty_variations_without_logo[]");
        const _quantities = [];

        for (const quantity of _quantitiesQuery) {
            const splittedResult = quantity.split(",");
            _quantities.push({ id: +splittedResult[0], quantity: +splittedResult[1], price: +splittedResult[2] });
        }

        setQuantities(_quantities);

        const _coloredQuantitiesQuery = params.getAll("qty_variations_with_logo[]");
        const _coloredQuantities = [];

        for (const quantity of _coloredQuantitiesQuery) {
            const splittedResult = quantity.split(",");
            const original = _quantities.find(x => x.quantity === +splittedResult[1]);

            if (original) {
                const diff = +splittedResult[2] - original.price;
                _coloredQuantities.push({ id: +splittedResult[0], quantity: +splittedResult[1], price: +diff });
            }
            else {
                _coloredQuantities.push({ id: +splittedResult[0], quantity: +splittedResult[1], price: +splittedResult[2] });
            }
        }

        setColoredQuantities(_coloredQuantities);

        const selectedOriginalQuantityId = _quantities[0]?.id;
        const quantityEntry = _quantities.find(x => x.id === Number(selectedOriginalQuantityId));
        let _quantityOverride;

        if (quantityEntry) {
            const quantityOverride = _coloredQuantities.find(x => x.quantity === quantityEntry.quantity);
            if (quantityOverride) {
                _quantityOverride = quantityOverride.id
            }
        }

        setPersonalizeInfo(prev => ({
            ...prev,
            quantity: selectedOriginalQuantityId,
            quantityOverride: _quantityOverride
        }));

    }, [params]);

    useEffect(() => {
        const listener = () => {
            if (document.activeElement.type === "number") {
                document.activeElement.blur();
            }
        }

        document.addEventListener("wheel", listener);

        return () => {
            document.removeEventListener("wheel", listener);
        };
    });

    useEffect(() => {
        updatePrice();
    }, [personalizeInfo])

    const updatePrice = () => {
        if (!personalizeInfo || !quantities || !coloredQuantities) {
            return;
        }

        const selectedQuantity = personalizeInfo.quantity;
        const selectedColoredQuantity = personalizeInfo.quantityOverride;

        let price = 0;
        if (selectedQuantity) {
            price += quantities.find(x => x.id == Number(selectedQuantity))?.price;
        }

        if (companyInfo.logoColor == "1" && selectedColoredQuantity) {
            price += coloredQuantities.find(x => x.id == Number(selectedColoredQuantity))?.price;
        }

        setPrice(price);
    }

    const setPostRequestValue = () => {

        if (!canvas) {
            return;
        }

        const body = {
            checkInfo: {
                personalizeInfo: personalizeInfo,
                companyInfo: companyInfo,
                accountInfo: accountInfo,
                checkInfo: checkInfo
            },
            selectedProduct: {
                id: Number(id),
                variation: {
                    id: Number(companyInfo.logoColor) === 1 ? Number(personalizeInfo.quantityOverride) : Number(personalizeInfo.quantity)
                },
                name: ''
            },
            selectedBackground: {
                id: Number(personalizeInfo.selectedBackgroundId),
                name: backgrounds.find(x => x.id === personalizeInfo.selectedBackgroundId)?.name
            },
            endFile: canvas.toDataURL({
                // format: "jpeg"
            })
        };

        setTextAreaValue(JSON.stringify(body));
        //console.log("POST REQUEST", body);
    }

    const personalizeInfoFormChangeHandler = e => {
        setPersonalizeInfo({
            ...personalizeInfo,
            [e.target.name]: e.target.value
        });

        if (e.target.name === "quantity") {
            setColoredQuantity(e.target.value);
        }

        // setPostRequestValue();
    }

    const companyInfoFormChangeHandler = (e) => {
        const { name, value, checked } = e.target;

        const oldValue = companyInfo[name];

        const newCompanyInfo = {
            ...companyInfo,
            [name]: name === "customerWillProvideImageOnEmail" || name.endsWith("Bold") ? checked : value,
        };
        setCompanyInfo(newCompanyInfo);

        if (["city", "state", "zip"].includes(name)) {
            updateCanvasValues(
                canvas,
                fieldMapping,
                "cityStateZip",
                `${newCompanyInfo.city} ${newCompanyInfo.state} ${newCompanyInfo.zip}`
            );
        } else {
            updateCanvasValues(canvas, fieldMapping, name, value);
        }

        boldHandler(canvas, fieldMapping, name, checked);
        if (name === "logoType" && value === "0") {
            setCompanyInfo((prev) => ({ ...prev, logoBase64: "", logoColor: "0" }));
            setColoredQuantity(personalizeInfo.quantity);
            clearLogo(canvas, fieldMapping, fieldMappingDefaultPositions);
            removeCompanyAddress2IfEmpty(canvas, fieldMapping, fieldMappingDefaultPositions);
        }

        if (name === "logoPosition") {
            changeLogoPosition(canvas, value, fieldMapping, fieldMappingDefaultPositions);
        }

        if (name === "companyAddress2" && !oldValue !== !value) {
            const logoPositionChangeResult = changeLogoPosition(canvas, companyInfo.logoPosition, fieldMapping, fieldMappingDefaultPositions);
            if (!logoPositionChangeResult) {
                removeCompanyAddress2IfEmpty(canvas, fieldMapping, fieldMappingDefaultPositions);
            }
        }

        if (name === "logoColor") {
            if (value === "0") {
                setPersonalizeInfo((prev) => ({ ...prev, quantityOverride: null }));
            } else {
                setColoredQuantity(personalizeInfo.quantity);
                // const selectedOriginalQuantityId = personalizeInfo.quantity;
                // const quantityEntry = quantities.find(x => x.id === Number(selectedOriginalQuantityId));

                // if (quantityEntry) {
                //     const quantityOverride = coloredQuantities.find(x => x.quantity === quantityEntry.quantity);
                //     if (quantityOverride) {
                //         setPersonalizeInfo(prev => ({ ...prev, quantityOverride: quantityOverride.id }));
                //     }
                // }
            }
            /** @type {fabric.Image[]} */
            const images = canvas.getObjects("image");
            const img = images.find((item) => item.fill === "logo");
            if (img) {
                img.filters = [];
                if (value === "0") {
                    img.filters.push(new fabric.Image.filters.Grayscale());
                }
                img.applyFilters();
                canvas.renderAll();
            }
        }

        // setPostRequestValue();
    };

    const setColoredQuantity = (value) => {

        const selectedOriginalQuantityId = value;
        const quantityEntry = quantities.find(x => x.id === Number(selectedOriginalQuantityId));

        if (quantityEntry) {
            const quantityOverride = coloredQuantities.find(x => x.quantity === quantityEntry.quantity);
            if (quantityOverride) {
                setPersonalizeInfo(prev => ({ ...prev, quantityOverride: quantityOverride.id }));
            }
        }
    }

    const accountInfoFormChangeHandler = e => {
        const newAccountInfo = {
            ...accountInfo,
            [e.target.name]: e.target.name.endsWith("Bold") ? e.target.checked : e.target.value
        };
        setAccountInfo(newAccountInfo);

        const { name, value } = e.target;

        boldHandler(canvas, fieldMapping, name, e.target.checked);
        if (["bankCity", "bankState", "bankZip"].includes(name)) {
            updateCanvasValues(
                canvas,
                fieldMapping,
                "bankCityStateZip",
                `${newAccountInfo.bankCity} ${newAccountInfo.bankState} ${newAccountInfo.bankZip}`
            );
        } else {
            updateCanvasValues(canvas, fieldMapping, name, value);
        }
        setPostRequestValue();

        return newAccountInfo;
    };

    const checkInfoFormChangeHandler = e => {

        setCheckInfo({
            ...checkInfo,
            [e.target.name]:
                [
                    "linesOnCheck",
                    "extraSignatureLine",
                    "reverseNumbering",
                    "userWillProvideAVoidedCheck",
                ].includes(e.target.name) || e.target.name.endsWith("Bold")
                    ? e.target.checked
                    : e.target.name === "proofBeforePrinting"
                        ? e.target.value === "true"
                        : e.target.value,
        });

        const { name, value } = e.target;

        setPostRequestValue();
        boldHandler(canvas, fieldMapping, name, e.target.checked);

        updateCanvasValues(canvas, fieldMapping, name, value);
        extraSignatureChangeHandler(canvas, e.target.name, e.target.checked);
        linesOnCheckHandler(canvas, e.target.name, e.target.checked);
    };

    const goToTab = tabName => {
        const newActiveTab = ActiveTabEnum[tabName] ? ActiveTabEnum[tabName] : +tabName;
        setActiveTab(newActiveTab);
        setMaxActiveTab(Math.max(newActiveTab, maxActiveTab));
    };
    const logoFileSelectedHandler = async (event) => {
        const file = event.target.files[0];
        const base64 = await toBase64(file);
        if (!base64) {
            return;
        }
        setCompanyInfo(prev => ({ ...prev, logoBase64: base64 }));
        addLogo(base64, canvas, companyInfo.logoPosition, fieldMapping, fieldMappingDefaultPositions, companyInfo.logoColor);
    }

    // const logoFileSelectedHandler = async ({ base64, url }) => {
    //     if (!base64) {
    //         return;
    //     }
    //     setCompanyInfo(prev => ({ ...prev, logoBase64: base64 }));
    //     addLogo(url, canvas, companyInfo.logoPosition, fieldMapping, fieldMappingDefaultPositions, companyInfo.logoColor);
    // }

    const getChildren = () => {
        return (<form action={ADD_TO_CART_URL} method="post" target="_parent">
            {cartItemKey && <input id="cart_item_key" name="cart_item_key" value={cartItemKey} style={{ display: 'none' }} readOnly />}
            <textarea id="product" name="product" value={textAreaValue} style={{ display: 'none' }} readOnly />
            <div className="" role="toolbar" aria-label="Toolbar with button groups">
                <div id="add-to-cart-container" className="">
                    <Button
                        variant="primary"
                        type="submit"
                        disabled={!allowCheckInfoTab || !allowAccountInfoTab || (!checkInfo.checkStartingNumber && isRequiredMap.checkStartingNumber)}
                    >
                        {cartItemKey ? "Update cart" : "Add to cart"}
                    </Button>
                </div>
            </div>
        </form>);
    }

    const voidedCheckFileHandler = async (event) => {
        const file = event.target.files[0];
        const base64 = await toBase64(file);
        if (!base64) {
            return;
        }
        setCheckInfo((prev) => ({ ...prev, voidedCheckBase64: base64 }));
    };
//console.log(isRequiredMap);

    const selectBackground = (background, pv = productVariant, linesOnCheck = checkInfo.linesOnCheck) => {
        setShowPreview(true);
        setPersonalizeInfo(prev => ({ ...prev, selectedBackgroundId: background.id }));

        initCanvas(background, pv, linesOnCheck, ({ currentCanvas, fieldMapping, isBoldMap }) => {
            if (companyInfo.logoBase64) {
                addLogo(
                    companyInfo.logoBase64,
                    currentCanvas,
                    companyInfo.logoPosition,
                    fieldMapping,
                    fieldMapping.filter((x) => x.field !== undefined),
                    companyInfo.logoColor
                );
            }
            updateCanvasValuesWrapper(currentCanvas, fieldMapping, companyInfo);
            updateCanvasValuesWrapper(currentCanvas, fieldMapping, accountInfo);
            updateCanvasValuesWrapper(currentCanvas, fieldMapping, checkInfo);

            const companyInfoNew = {};
            const accountInfoNew = {};
            const checkInfoNew = {};
            Object.keys(isBoldMap).forEach(key => {
                if (companyInfo.hasOwnProperty(key)) {
                    companyInfoNew[`${key}Bold`] = isBoldMap[key];
                }
                if (accountInfo.hasOwnProperty(key)) {
                    accountInfoNew[`${key}Bold`] = isBoldMap[key];
                }
                if (checkInfo.hasOwnProperty(key)) {
                    checkInfoNew[`${key}Bold`] = isBoldMap[key];
                }
            });

            setCompanyInfo(oldValue => ({ ...companyInfoNew, ...oldValue }));
            setAccountInfo(oldValue => ({ ...accountInfoNew, ...oldValue }));
            setCheckInfo(oldValue => ({ ...checkInfoNew, ...oldValue }));
        });

        // I know that this does not make any sense, but it fixed the issue, please forgive.
        setTimeout(() => {
            const oldAddress = companyInfo.companyAddress;
            setCompanyInfo({ ...companyInfo, companyAddress: '' });
            setCompanyInfo({ ...companyInfo, companyAddress: oldAddress });
        }, 500);
    };

    // background object
    const initCanvas = (background, productVariant, linesOnCheck, callback) => {

        if (canvas) {
            canvas.dispose();
            setCanvas(null);
        }

        const currentCanvas = new fabric.Canvas('fabric', { selection: false });
        setCanvas(currentCanvas);

        const params = {
            background,
            productVariant
        };

        intializeCanvas(currentCanvas, params, linesOnCheck, ({ fieldMapping, isRequiredMap, isBoldMap }) => {
            setFieldMapping(fieldMapping);
            setIsRequiredMap(isRequiredMap);
            setIsBoldMap({ ...isBoldMap, companyName: true, bankName: true });

            const fieldMappingDefaultPositions = fieldMapping
                .filter(x => x.field !== undefined)
                .map(x => ({ ...x, annotation: { left: x.annotation.left, top: x.annotation.top } }));

            setFieldMappingDefaultPositions(fieldMappingDefaultPositions);
            callback({ currentCanvas, fieldMapping, isBoldMap });
        });
    }

    const toggleShowFront = () => {
        setShowFront(!showFront);
    }

    const highlightElement = (e) => {
        highlightAnnotation(canvas, fieldMapping, e);
    }

    // here handle the logic
    useEffect(() => {
        initializeQuantitiesCallback();

        const fetchData = async (wpProductId) => {
            setShowPreviewGlobal(false);
            setLoading(true);

            const backgrounds = await loadBackgrounds();

            const productVariant = await loadProductVariantFromWPProductId(wpProductId);
            if (!backgrounds || !productVariant) {
                setLoadProductError(true);
                setLoading(false);
                return;
            }
            const filteredBackgrounds = backgrounds.filter(
                (background) =>
                    !productVariant.backgrounds || JSON.parse(productVariant.backgrounds).includes(background.id)
            );
            setBackgrounds(filteredBackgrounds);

            selectBackground(filteredBackgrounds[0], productVariant)

            setProductVariant(productVariant);
            setLoading(false);
            setShowPreviewGlobal(true);
        }

        const fetchUsingCartItem = async (cartItemKey) => {
            setShowPreviewGlobal(false);
            setLoading(true);

            const backgrounds = await loadBackgrounds();

            const wpProductInfo = await getProductJsonFromWP(cartItemKey);

            const selectedWpProductId = wpProductInfo.data.customized_data.selectedProduct.id;
            const productVariant = await loadProductVariantFromWPProductId(selectedWpProductId);
            setProductVariant(productVariant);

            const filteredBackgrounds = backgrounds.filter(
                (background) =>
                    !productVariant.backgrounds || JSON.parse(productVariant.backgrounds).includes(background.id)
            );
            setBackgrounds(filteredBackgrounds);
            const selectedBackgroundId = wpProductInfo.data.customized_data.selectedBackground.id;
            const background = filteredBackgrounds.find(x => x.id === selectedBackgroundId);

            const { companyInfo, personalizeInfo, accountInfo, checkInfo } = wpProductInfo.data.customized_data.checkInfo;

            if (wpProductInfo.data.logo_file.startsWith("http")) {
                wpProductInfo.data.logo_file = await getBase64FromUrl(wpProductInfo.data.logo_file);
            }

            initCanvas(background, productVariant, checkInfo.linesOnCheck, ({ currentCanvas, fieldMapping, isBoldMap }) => {
                let logoFile = wpProductInfo.data.logo_file;
                if (logoFile) {
                    companyInfo.logoBase64 = logoFile;
                    addLogo(
                        logoFile,
                        currentCanvas,
                        companyInfo.logoPosition,
                        fieldMapping,
                        fieldMapping.filter((x) => x.field !== undefined),
                        companyInfo.logoColor
                    );
                }
                resizeCallback();
                setShowPreview(true);
                setLoading(false);
                Object.keys(isBoldMap).forEach(key => {
                    if (companyInfo.hasOwnProperty(key)) {
                        companyInfo[`${key}Bold`] = isBoldMap[key];
                    }
                    if (accountInfo.hasOwnProperty(key)) {
                        accountInfo[`${key}Bold`] = isBoldMap[key];
                    }
                    if (checkInfo.hasOwnProperty(key)) {
                        checkInfo[`${key}Bold`] = isBoldMap[key];
                    }
                });

                setPersonalizeInfo(personalizeInfo);
                setCompanyInfo(companyInfo);
                setAccountInfo(accountInfo);
                setCheckInfo(checkInfo);

                updateCanvasValuesWrapper(currentCanvas, fieldMapping, companyInfo);
                updateCanvasValuesWrapper(currentCanvas, fieldMapping, accountInfo);
                updateCanvasValuesWrapper(currentCanvas, fieldMapping, checkInfo);

                setShowPreviewGlobal(true);
                updatePrice();
            });
        }

        const cart_item_key = params.get("cart_item_key");

        if (cart_item_key) {
            fetchUsingCartItem(cart_item_key);
        }
        else {
            fetchData(params.get("id"));
        }

    }, [params, initializeQuantitiesCallback]);

    useEffect(() => {
        if (!loading) {
            setTimeout(() => resizeCallback(), 100);
        }
    }, [loading, resizeCallback]);

    useEffect(() => {
        if (!id) {
            return;
        }
        getProduct(id).then(setProductData);
    }, [id]);

    useEffect(() => {
        setTimeout(() => resizeCallback(), 100);

        window.addEventListener('resize', resizeCallback, false);
        window.addEventListener('load', resizeCallback, false);

        return () => {
            window.removeEventListener('resize', resizeCallback, false);
            window.removeEventListener('load', resizeCallback, false);
        }
    }, [canvas, resizeCallback]);

    const cartItemKey = params.get("cart_item_key")

    const validateAccountInfo = (overrideAccountInfo = null, inputName = null) => {
        const allErrors = {};
        let _accountInfo = overrideAccountInfo ?? accountInfo;

        if (inputName) {
            if (inputName == "bankName" && isRequiredMap.bankName && (!_accountInfo.bankName || _accountInfo.bankName === "")) {
                allErrors.bankName = "Please provide a valid bank name.";
            }

            if (inputName == "bankAddress" && isRequiredMap.bankAddress && (!_accountInfo.bankAddress || _accountInfo.bankAddress === "")) {
                allErrors.bankAddress = "Please provide a valid bank address.";
            }

            if (inputName == "bankCity" && isRequiredMap.bankCity && (!_accountInfo.bankCity || _accountInfo.bankCity === "")) {
                allErrors.bankCity = "Please provide a valid bank city.";
            }

            if (inputName == "bankState" && isRequiredMap.bankState && (!_accountInfo.bankState || _accountInfo.bankState === "")) {
                allErrors.bankState = "Please provide a valid bank state.";
            }

            if (inputName == "bankZip" && isRequiredMap.bankZip && (!_accountInfo.bankZip || _accountInfo.bankZip === "")) {
                allErrors.bankZip = "Please provide a valid bank zip code.";
            }

            if (inputName == "nineDigitRoutingNumber" && isRequiredMap.nineDigitRoutingNumber && (!_accountInfo.nineDigitRoutingNumber || _accountInfo.nineDigitRoutingNumber === "")) {
                allErrors.nineDigitRoutingNumber = "Please provide a valid routing number.";
            }

            if (!!_accountInfo.nineDigitRoutingNumber && _accountInfo.nineDigitRoutingNumber.length !== 9) {
                allErrors.nineDigitRoutingNumber = "Invalid routing number";
            }

            if (inputName == "accountNumber" && isRequiredMap.accountNumber && (!_accountInfo.accountNumber || _accountInfo.accountNumber === "")) {
                allErrors.accountNumber = "Please provide a valid account number.";
            }

            if (inputName == "accountNumber" && _accountInfo.accountNumber && _accountInfo.accountNumber !== _accountInfo.confirmAccountNumber) {
                allErrors.confirmAccountNumber = "Account number and Confirm account number are not the same.";
            }

            if (inputName == "routingFractionNumber" && isRequiredMap.routingFractionNumber && (!_accountInfo.routingFractionNumber || _accountInfo.routingFractionNumber === "")) {
                allErrors.routingFractionNumber = "Please provide a routing fraction number.";
            }

            return allErrors;
        }

        if (isRequiredMap.bankName && (!_accountInfo.bankName || _accountInfo.bankName === "")) {
            allErrors.bankName = "Please provide a valid bank name.";
        }

        if (isRequiredMap.bankAddress && (!_accountInfo.bankAddress || _accountInfo.bankAddress === "")) {
            allErrors.bankAddress = "Please provide a valid bank address.";
        }

        if (isRequiredMap.bankCity && (!_accountInfo.bankCity || _accountInfo.bankCity === "")) {
            allErrors.bankCity = "Please provide a valid bank city.";
        }

        if (isRequiredMap.bankState && (!_accountInfo.bankState || _accountInfo.bankState === "")) {
            allErrors.bankState = "Please provide a valid bank state.";
        }

        if (isRequiredMap.bankZip && (!_accountInfo.bankZip || _accountInfo.bankZip === "")) {
            allErrors.bankZip = "Please provide a valid bank zip code.";
        }

        if (isRequiredMap.nineDigitRoutingNumber && (!_accountInfo.nineDigitRoutingNumber || _accountInfo.nineDigitRoutingNumber === "")) {
            allErrors.nineDigitRoutingNumber = "Please provide a valid routing number.";
        }

        if (!!_accountInfo.nineDigitRoutingNumber && _accountInfo.nineDigitRoutingNumber.length !== 9) {
            allErrors.nineDigitRoutingNumber = "Invalid routing number";
        }

        if (isRequiredMap.accountNumber && (!_accountInfo.accountNumber || _accountInfo.accountNumber === "")) {
            allErrors.accountNumber = "Please provide a valid account number.";
        }

        if (_accountInfo.accountNumber && _accountInfo.accountNumber !== _accountInfo.confirmAccountNumber) {
            allErrors.confirmAccountNumber = "Account number and Confirm account number are not the same.";
        }

        if (isRequiredMap.routingFractionNumber && (!_accountInfo.routingFractionNumber || _accountInfo.routingFractionNumber === "")) {
            allErrors.routingFractionNumber = "Please provide a routing fraction number.";
        }

        return allErrors;
    };

    const allowCheckInfoTab = useMemo(() => {
        const accountInfoErrors = validateAccountInfo();
        return !Object.keys(accountInfoErrors).length;
    }, [accountInfo, isRequiredMap]);

    const allowAccountInfoTab = useMemo(() => {
        const checkObject = { ...companyInfo };
        // No need to check
        delete checkObject.companyAddress;

        if (companyInfo.logoType === "0" || companyInfo.customerWillProvideImageOnEmail) {
            checkObject.logoBase64 = "-";
        }
        

        Object.keys(checkObject).forEach((key) => {

            if ((!isRequiredMap.hasOwnProperty(key) && key != "logoBase64") || (isRequiredMap.hasOwnProperty(key) && !isRequiredMap[key] && !checkObject[key])) {
                checkObject[key] = "-";
            }
        });

        //console.log(checkObject);
        return !isEmpty(checkObject);
    }, [companyInfo, isRequiredMap]);

    return (
        <div className="container-fluid">
            <div className="personalize_wrapper">
                <div className="personalize_tabs">
                    <div className="tabsInner">
                        <Tabs id="uncontrolled-tab-example" activeKey={activeTab} className="mb-3" onSelect={k => goToTab(k)}>
                            <Tab
                                eventKey={ActiveTabEnum.StartPage}
                                tabClassName="passed"
                                title="Personalize"
                            >
                                {loading ? (
                                    <Spinner as="div" animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                ) : loadProductError ? (
                                    <Alert variant="danger">Product Information not found</Alert>
                                ) : (
                                    <StartPage
                                        quantities={quantities}
                                        personalizeInfo={personalizeInfo}
                                        personalizeInfoFormChangeHandler={personalizeInfoFormChangeHandler}
                                        backgrounds={backgrounds}
                                        selectBackground={selectBackground}
                                        goToTab={goToTab}
                                    >{getChildren()}</StartPage>
                                )}
                            </Tab>
                            <Tab
                                eventKey={ActiveTabEnum.CompanyInfo}
                                tabClassName={!loading && (cartItemKey || maxActiveTab >= ActiveTabEnum.CompanyInfo) ? "passed" : ""}
                                title="Company Info"
                                disabled={isEmpty({ ...personalizeInfo, quantityOverride: "-" })}
                            >
                                <h4 className="personalizeHeading">COMPANY INFO</h4>
                                <CompanyInfo
                                    highlightElement={highlightElement}
                                    companyInfo={companyInfo}
                                    companyInfoFormChangeHandler={companyInfoFormChangeHandler}
                                    logoFileSelectedHandler={logoFileSelectedHandler}
                                    coloredQuantities={coloredQuantities}
                                    goToTab={goToTab}
                                    isRequiredMap={isRequiredMap}
                                    isBoldMap={isBoldMap}
                                    allowAccountInfoTab={allowAccountInfoTab}
                                    isRequiredMap={isRequiredMap}
                                >
                                    {getChildren()}
                                </CompanyInfo>
                            </Tab>
                            <Tab
                                eventKey={ActiveTabEnum.AccountInfo}
                                tabClassName={!loading && (cartItemKey || maxActiveTab >= ActiveTabEnum.AccountInfo) ? "passed" : ""}
                                title="Account Info"
                                disabled={!allowAccountInfoTab}
                            >
                                <h4 className="personalizeHeading">ACCOUNT INFO</h4>
                                <AccountInfo
                                    highlightElement={highlightElement}
                                    accountInfo={accountInfo}
                                    accountInfoFormChangeHandler={accountInfoFormChangeHandler}
                                    goToTab={goToTab}
                                    isRequiredMap={isRequiredMap}
                                    isBoldMap={isBoldMap}
                                    validate={validateAccountInfo}
                                >{getChildren()}</AccountInfo>
                            </Tab>
                            <Tab
                                eventKey={ActiveTabEnum.CheckInfo}
                                tabClassName={!loading && (cartItemKey || maxActiveTab >= ActiveTabEnum.CheckInfo) ? "passed" : ""}
                                title="Check Info"
                                disabled={!allowCheckInfoTab}
                            >
                                <h4 className="personalizeHeading">CHECK INFO</h4>
                                <CheckInfo isBoldMap={isBoldMap} checkInfo={checkInfo} checkInfoFormChangeHandler={checkInfoFormChangeHandler} onVoidedCheckFileSelect={voidedCheckFileHandler} />
                                <form action={ADD_TO_CART_URL} method="post" target="_parent">
                                    {cartItemKey && <input id="cart_item_key" name="cart_item_key" value={cartItemKey} style={{ display: 'none' }} readOnly />}
                                    <textarea id="product" name="product" value={textAreaValue} style={{ display: 'none' }} readOnly />
                                    <div className="btn-toolbar justify-content-between" role="toolbar" aria-label="Toolbar with button groups">
                                        <div className="">
                                            <Button variant="warning" onClick={() => goToTab("AccountInfo")}>Back</Button>
                                        </div>
                                        <div className="">
                                            <Button
                                                variant="primary"
                                                type="submit"
                                                disabled={
                                                    (!checkInfo.checkStartingNumber &&
                                                        isRequiredMap.checkStartingNumber) ||
                                                    (!checkInfo.textAboveSignatureLine &&
                                                        isRequiredMap.textAboveSignatureLine) ||
                                                    (checkInfo.userWillProvideAVoidedCheck &&
                                                        !checkInfo.voidedCheckBase64) ||
                                                    checkInfo.proofBeforePrinting === undefined
                                                }
                                            >
                                                {cartItemKey ? "Update cart" : "Add to cart"}
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
                <div className="personalize_preview">
                    <div className="previewInner" style={{ display: !showPreviewGlobal && 'none' }}>
                        <div className={`flip-card${!showFront ? " back" : ""}`} style={{ height: canvasHeight }}>
                            <div className="flip-card-inner" style={{ height: canvasHeight }}>
                                <div className="preview_image" style={{ height: canvasHeight }}>
                                    {!showPreview && <span>Once you select the background the check preview will show up</span>}
                                    <canvas id="fabric" />
                                </div>
                                <div className="preview_image" style={{ height: canvasHeight }}>
                                    {productVariant?.backImageFileName && <img alt="backImage" style={{ objectFit: "cover", width: "568px", height: "265px" }} src={`${BackEndUrl}/uploads/${productVariant?.backImageFileName}`} />}
                                    {!productVariant?.backImageFileName && <span>Back image is not available for this particular check</span>}
                                </div>
                            </div>
                        </div>
                        <div style={{ marginTop: 64 }}>
                            <div>
                                <span className="check-price">${price.toFixed(2)}</span>
                                {showPreview && (<div className="FrontBack_Btns">
                                    <Button variant="primary" disabled={showFront} onClick={toggleShowFront}>Front</Button>
                                    <Button variant="warning" disabled={!showFront} onClick={toggleShowFront}>Back</Button>
                                </div>)}
                            </div>
                            {showPreview && <p className="previewNote">
                                This is a preview image only. Adjustments for layout, sizing and spacing will be made before printing.
                            </p>}
                            {showPreview && productData && (
                                <>
                                    <p className="mb-0">{productData.title}</p>
                                    <p className="previewNote mt-2" dangerouslySetInnerHTML={{ __html: productData.description }}></p>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PersonalizeCheck;