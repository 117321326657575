import React, { useState } from "react";
import "./personalizeForm.css";
import { Row, Col, Form, Button } from "react-bootstrap";
import FormItemWithBold from "../FormItemWithBold";

const AccountInfo = ({ accountInfo, accountInfoFormChangeHandler, goToTab, highlightElement, isRequiredMap, isBoldMap, validate, children }) => {
    const [validated, setValidated] = useState(false);
    const [errors, setErrors] = useState({});
    const [focusedEl, setFocusedEl] = useState("");

    const highlightElementWrapper = e => {
        highlightElement(e);
        setFocusedEl(e.target.name);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const allErrors = validate();

        if (Object.keys(allErrors).length === 0) {
            setValidated(true);
            setErrors({});
            goToTab("CheckInfo");
            return;
        }

        setErrors(allErrors);
        setValidated(false);
    };

    const previousTab = () => {
        goToTab("CompanyInfo");
    };

    const preventCopyOrPaste = (e) => {
        e.preventDefault();
        return false;
    };

    const handleRoutingFractionNumberChange = (e) => {
        if (e.target.value && !/([0-9]|-|\/)$/.test(e.target.value)) {
            return;
        }
        accountInfoFormChangeHandler(e);
    };

    const formChangedWrapper = (e) => {
        const newState = accountInfoFormChangeHandler(e);
        const allErrors = validate(newState, e.target.name);
        
        if (Object.keys(allErrors).length === 0) {
            //setValidated(true);
            setErrors({});
            return;
        }

        setErrors(allErrors);
        setValidated(false);
    }

    const commonProps = {
        errors,
        data: accountInfo,
        onChange: formChangedWrapper,
        onFocus: highlightElementWrapper,
        isBoldMap
    };

    return (
        <div className="personalizeFrom">
            <Form noValidate validated={false} onSubmit={handleSubmit}>
                <Row>
                    <Col sm={12} className="form-col">
                        <FormItemWithBold
                            required={isRequiredMap.bankName}
                            skipBold={true}
                            name="bankName"
                            type="text"
                            placeholder="Bank Name"
                            isRequiredMap={isRequiredMap}
                            {...commonProps}
                        />
                    </Col>
                    <Col sm={12} className="form-col">
                        <FormItemWithBold
                            required={isRequiredMap.bankAddress}
                            name="bankAddress"
                            type="text"
                            placeholder="Bank Address"
                            isRequiredMap={isRequiredMap}
                            {...commonProps}
                        />
                    </Col>
                    <Col sm={4} className="form-col">
                        <FormItemWithBold
                            required={isRequiredMap.bankCity}
                            name="bankCity"
                            type="text"
                            placeholder="City"
                            isRequiredMap={isRequiredMap}
                            {...commonProps}
                        />
                    </Col>
                    <Col sm={4} className="form-col">
                        <FormItemWithBold
                            required={isRequiredMap.bankState}
                            name="bankState"
                            type="text"
                            placeholder="State"
                            isRequiredMap={isRequiredMap}
                            {...commonProps}
                        />
                    </Col>
                    <Col sm={4} className="form-col">
                        <FormItemWithBold
                            required={isRequiredMap.bankZip}
                            name="bankZip"
                            type="text"
                            placeholder="Zipcode"
                            isRequiredMap={isRequiredMap}
                            {...commonProps}
                        />
                    </Col>
                    {isBoldMap.bankCityStateZip && (
                        <Col sm={4} className="form-col">
                            <Form.Group className="mb-3">
                                <Form.Check
                                    id={`bankCityStateZipBold-bold-check`}
                                    label="Bold"
                                    name="bankCityStateZipBold"
                                    checked={accountInfo.bankCityStateZipBold}
                                    onChange={accountInfoFormChangeHandler}
                                />
                            </Form.Group>
                        </Col>
                    )}
                    <Col sm={12} className="form-col">
                        <FormItemWithBold
                            required={isRequiredMap.nineDigitRoutingNumber}
                            name="nineDigitRoutingNumber"
                            type="number"
                            min={1}
                            onPaste={preventCopyOrPaste}
                            onCopy={preventCopyOrPaste}
                            placeholder="9 Digit routing Number"
                            isRequiredMap={isRequiredMap}
                            {...commonProps}
                        />
                    </Col>
                    <Col sm={12} className="form-col">
                        <FormItemWithBold
                            required={isRequiredMap.accountNumber}
                            name="accountNumber"
                            type="number"
                            min={1}
                            placeholder="Account Number"
                            onPaste={preventCopyOrPaste}
                            onCopy={preventCopyOrPaste}
                            isRequiredMap={isRequiredMap}
                            {...commonProps}
                        />
                    </Col>
                    <Col sm={12} className="form-col">
                        <FormItemWithBold
                            required={isRequiredMap.accountNumber}
                            name="confirmAccountNumber"
                            type="number"
                            min={1}
                            onPaste={preventCopyOrPaste}
                            onCopy={preventCopyOrPaste}
                            placeholder="Confirm Account Number"
                            isRequiredMap={isRequiredMap}
                            {...commonProps}
                        />
                    </Col>
                    <Col sm={12} className="form-col">
                        <Form.Group className="mb-3">
                            <Form.Label>Routing Fraction Number{isRequiredMap && isRequiredMap['routingFractionNumber'] != undefined && isRequiredMap['routingFractionNumber'] === true ? (<span style={{ color: 'red' }}> *</span>) : null}</Form.Label>
                            <Form.Control
                                required={isRequiredMap.routingFractionNumber}
                                onFocus={highlightElement}
                                name="routingFractionNumber"
                                isInvalid={!!errors.routingFractionNumber}
                                value={accountInfo.routingFractionNumber}
                                onChange={handleRoutingFractionNumberChange}
                                min={1}
                                onPaste={preventCopyOrPaste}
                                onCopy={preventCopyOrPaste}
                                placeholder="Routing Fraction Number"
                                isRequiredMap={isRequiredMap}
                            />
                            <Form.Control.Feedback type="invalid">{errors.routingFractionNumber}</Form.Control.Feedback>
                        </Form.Group>
                        {isBoldMap.routingFractionNumber && (
                            <Form.Group className="mb-3">
                                <Form.Check
                                    id="routingFractionNumber-bold-check"
                                    label="Bold"
                                    name="routingFractionNumberBold"
                                    checked={accountInfo.routingFractionNumberBold || false}
                                    onChange={accountInfoFormChangeHandler}
                                />
                            </Form.Group>
                        )}
                    </Col>
                </Row>
                <hr />
                <div
                    className="btn-toolbar justify-content-between"
                    role="toolbar"
                    aria-label="Toolbar with button groups"
                >
                    <div style={{ display: "inherit" }}>
                        <div className="me-1">
                            <Button variant="warning" onClick={previousTab}>Back</Button>
                        </div>
                        <div className="">
                            <Button type="submit">Next</Button>
                        </div>
                    </div>
                    <div>
                        {children}
                    </div>
                </div>
            </Form>
        </div>
    );
}

export default AccountInfo;