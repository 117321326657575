export const WPBaseUrl = "https://abccheckprinting.com";
export const BackEndUrl = "https://api.abccheckprinting.com";

export const ADD_TO_CART_URL = `${WPBaseUrl}/api/customizer/add_to_cart.php`;

export const loadBackgrounds = async () => {
    const backgroundResponse = await fetch(`${BackEndUrl}/api/Backgrounds`, { method: 'GET', mode: 'cors' });
    if (!backgroundResponse.ok) {
        return null;
    }
    return await backgroundResponse.json();
}

export const loadProductVariantFromWPProductId = async wpProductId => {
    const url = `${BackEndUrl}/api/ProductVarient/GetProductDetailsByWPProductId/${wpProductId}`;
    try {
        const response = await fetch(url);

        if (!response.ok) {
            return null;
        }

        return await response.json();
    }
    catch {
        return null;
    }
}

export const getProductJsonFromWP = async cart_item_id => {
    const url = `${WPBaseUrl}/api/customizer/get_cart_item.php?cart_item_key=${cart_item_id}`;
    const productResponse = await fetch(url, { method: "GET", credentials: "include" });

    if (!productResponse.ok) {
        console.log("ERROR from WP, not success response");
        return null;
    }

    const json = await productResponse.json();
    return json;
}

export const getProduct = async (id) => {
    try {
        const url = `${WPBaseUrl}/api/customizer/get_product.php?id=${id}`;
        const response = await fetch(url, { method: "GET", credentials: "include" });

        if (!response.ok) {
            console.log("ERROR from WP, not success response");
            return null;
        }

        const json = await response.json();
        return json.data;
    } catch (err) {
        console.log("Get Product Error:", err)
    }
};
