import { Card, Col, Row, Form, Button } from "react-bootstrap";

const StartPage = ({
    quantities,
    personalizeInfo,
    personalizeInfoFormChangeHandler,
    backgrounds,
    selectBackground,
    goToTab,
    children
}) => {
    const goToNextPage = () => {
        goToTab('CompanyInfo');
    }

    return (
        <>
            <Row>
                <Col sm={12}>
                    <Form.Group className="mb-3">
                        <Form.Label>Quantity</Form.Label>
                        <Form.Select value={personalizeInfo.quantity ?? ''} onChange={personalizeInfoFormChangeHandler} name="quantity" >
                            <option>Select Quantity</option>
                            {quantities && quantities.map(x => (<option key={x.id} value={x.id}>{x.quantity} (${x.price.toFixed(2)})</option>))}
                        </Form.Select>
                    </Form.Group>
                </Col>
                <div className="mb-3">
                    <span>Select check color</span>
                </div>
                {backgrounds && backgrounds.map(background => (
                    <Col lg="6" xl="3"
                        key={background.id}
                        className="mb-2"
                        style={{ cursor: 'pointer' }}
                        onClick={() => selectBackground(background)}>
                        <Card key={background.id} className={+personalizeInfo.selectedBackgroundId === +background.id ? "border-success" : ""}>
                            <Card.Body>
                                <img
                                    alt={background.filePath}
                                    src={`https://api.abccheckprinting.com/uploads/${background.filePath}`}
                                    style={{ width: "100%", height: "auto" }} />
                                <p className="text-center mt-1 mb-0">{background.name}</p>
                            </Card.Body>
                        </Card>
                    </Col>))}
            </Row>
            <hr />

            <div
                className="btn-toolbar justify-content-between"
                role="toolbar"
                aria-label="Toolbar with button groups"
            >
                <div style={{ display: "inherit" }}>
                    <div className="me-1">
                        <Button disabled={!(personalizeInfo.selectedBackgroundId && personalizeInfo.quantity)} onClick={goToNextPage}>Next</Button>
                    </div>
                </div>
                <div>
                    {children}
                </div>
            </div>
        </>
    )
}

export default StartPage;